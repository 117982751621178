import React, { useState } from 'react'
import './style.css'
import tripleroom1 from '../../images/tripleroom1.jpg'
import tripleroom2 from '../../images/tripleroom2.jpg'
import tripleroom3 from '../../images/tripleroom3.jpg'
import tripleroom4 from '../../images/tripleroom4.jpg'
import { images ,images2,images3,images4} from '../../Helpers/CarouselData'
import {AiOutlineArrowLeft} from 'react-icons/ai'
import {IoBed, IoLogoNoSmoking, IoWifi} from 'react-icons/io5'
import {AiOutlineArrowRight} from 'react-icons/ai'
import { MdBalcony, MdOutlineBreakfastDining, MdRoomService } from 'react-icons/md'
import { FaParking } from 'react-icons/fa'
import {CgScreen} from 'react-icons/cg'
import {BsSnow} from 'react-icons/bs'

const RoomsBackground = () => {

  const [currImg, setCurrImg] = useState(0);
  const [currImg2, setCurrImg2] = useState(0);
  const [currImg3, setCurrImg3] = useState(0);
  const [currImg4, setCurrImg4] = useState(0);

  return (
    <div>
      <div className='rooms-background'>
        <h1>Rooms</h1>
      </div>

      <div className="row1">
      <div className="text">
        <h1><span>0.1 </span> Triple <span>Room</span></h1>
      </div>
        <div id="#room1" className="carousel">
          <div className='space-left'></div>
          <div className="container-carousel">
            <h1>Room Description</h1>
            <p>This triple room is a room with a wonderful mountain view. The beds are comfy based on our clients review and the size of the room is 40 square meters. The room also provides air conditioning and flat-screen TV.</p>
            
            <h1>Room Amenites</h1>
            <div className="row11">
              <IoBed style={{marginTop: 3, marginRight: 5, color: "rgb(125, 83, 0)"}}/>
              <p>2 Beds</p>
              <FaParking style={{marginTop: 3, marginRight: 5, color: "rgb(125, 83, 0)"}} />
              <p>Parking</p>
            </div>
            <div className="row2">
              <MdRoomService style={{marginTop: 3, marginRight: 5, color: "rgb(125, 83, 0)"}} />
              <p>Room Service</p>
              <MdOutlineBreakfastDining style={{marginTop: 3, marginRight: 5, color: "rgb(125, 83, 0)"}} />
              <p>Breakfast</p>
              <CgScreen style={{marginTop: 3, marginRight: 5, color: "rgb(125, 83, 0)"}} />
              <p>Flat Screen TV</p>
            </div>
            <div className="row3">
              <IoLogoNoSmoking style={{marginTop: 3, marginRight: 5, color: "rgb(125, 83, 0)"}} />
              <p>No Smoking</p>
              <IoWifi style={{marginTop: 3, marginRight: 5, color: "rgb(125, 83, 0)"}} />
              <p>Free Wi-fi</p>
              <BsSnow style={{marginTop: 3, marginRight: 5, color: "rgb(125, 83, 0)"}} />
              <p>Air Conditioning</p>
            </div>
            <div className="bookingbutton">
            <a target={'_blank'} href="https://www.booking.com/hotel/al/paradise-point.html?aid=304142&label=gen173rf-1FCAEoggI46AdIM1gDaAaIAQGYATG4ARjIAQzYAQHoAQH4AQKIAgGiAg5sb2NhbGhvc3Q6MzAwMKgCBLgClZ6XmgbAAgHSAiQ5ZmZhNWVjNi1jMDhjLTQxMTItOWE3Ni05YmQ3NTRhNjFkZTXYAgXgAgE&sid=d1164f564a38bfe6db3a85fed331963f&dest_id=-105824;dest_type=city;dist=0;group_adults=3;group_children=0;hapos=1;hpos=1;no_rooms=1;req_adults=3;req_children=0;room1=A%2CA%2CA;sb_price_type=total;sr_order=popularity;srepoch=1665519438;srpvid=d2d38ea6f27101f9;type=total;ucfs=1&#hotelTmpl" className="bookbutton2">Book Now</a>
            </div>
          </div>
          
          <div className="carouselInner" style={{backgroundImage: `url(${images[currImg].img})`}}>
            <div className="left" onClick={() =>  { currImg > 0 && setCurrImg(currImg - 1)}}><AiOutlineArrowLeft style={{cursor: "pointer", fontSize: 30}}/></div>
            <div className="center">
            </div>
            <div className="right" onClick={() => { currImg < images.length - 1  && setCurrImg(currImg + 1)}}><AiOutlineArrowRight style={{cursor: "pointer", fontSize: 30}}/></div>
          </div>
          <div className="space-right"></div>
        </div>
      </div>
      <div className="row1">
      <div className="text">
        <h1><span>0.2 </span>Standard Double <span>Room</span></h1>
      </div>
        <div className="carousel">
          <div className='space-left'></div>
          <div className="carouselInner" style={{backgroundImage: `url(${images2[currImg2].img})`}}>
            <div className="left" onClick={() =>  { currImg2 > 0 && setCurrImg2(currImg2 - 1)}}><AiOutlineArrowLeft style={{cursor: "pointer", fontSize: 30}}/></div>
            <div className="center"></div>
            <div className="right" onClick={() => { currImg2 < images2.length - 1  && setCurrImg2(currImg2 + 1)}}><AiOutlineArrowRight style={{cursor: "pointer", fontSize: 30}}/></div>
          </div>
          <div className="container-carousel">
            <h1>Room Description</h1>
            <p>This standard double room has a balcony with a wonderful city view. The bed is comfy based on our clients review and the size of the room is 30 square meters. The room also provides air conditioning and flat-screen TV.</p>
            
            <h1>Room Amenites</h1>
            <div className="row11">
              <IoBed style={{marginTop: 3, marginRight: 5, color: "rgb(125, 83, 0)"}}/>
              <p>1 Bed</p>
              <MdBalcony style={{marginTop: 3, marginRight: 5, color: "rgb(125, 83, 0)"}} />
              <p>Balcony</p>
              <FaParking style={{marginTop: 3, marginRight: 5, color: "rgb(125, 83, 0)"}} />
              <p>Parking</p>
            </div>
            <div className="row2">
              <MdRoomService style={{marginTop: 3, marginRight: 5, color: "rgb(125, 83, 0)"}} />
              <p>Room Service</p>
              <MdOutlineBreakfastDining style={{marginTop: 3, marginRight: 5, color: "rgb(125, 83, 0)"}} />
              <p>Breakfast</p>
              <CgScreen style={{marginTop: 3, marginRight: 5, color: "rgb(125, 83, 0)"}} />
              <p>Flat Screen TV</p>
            </div>
            <div className="row3">
              <IoLogoNoSmoking style={{marginTop: 3, marginRight: 5, color: "rgb(125, 83, 0)"}} />
              <p>No Smoking</p>
              <IoWifi style={{marginTop: 3, marginRight: 5, color: "rgb(125, 83, 0)"}} />
              <p>Free Wi-fi</p>
              <BsSnow style={{marginTop: 3, marginRight: 5, color: "rgb(125, 83, 0)"}} />
              <p>Air Conditioning</p>
            </div>
            <div className="bookingbutton">
            <a target={'_blank'} href="https://www.booking.com/hotel/al/paradise-point.html?aid=304142&label=gen173rf-1FCAEoggI46AdIM1gDaAaIAQGYATG4ARjIAQzYAQHoAQH4AQKIAgGiAg5sb2NhbGhvc3Q6MzAwMKgCBLgClZ6XmgbAAgHSAiQ5ZmZhNWVjNi1jMDhjLTQxMTItOWE3Ni05YmQ3NTRhNjFkZTXYAgXgAgE&sid=d1164f564a38bfe6db3a85fed331963f&dest_id=-105824;dest_type=city;dist=0;group_adults=3;group_children=0;hapos=1;hpos=1;no_rooms=1;req_adults=3;req_children=0;room1=A%2CA%2CA;sb_price_type=total;sr_order=popularity;srepoch=1665519438;srpvid=d2d38ea6f27101f9;type=total;ucfs=1&#hotelTmpl" className="bookbutton2">Book Now</a>
            </div>
          </div>
          <div className="space-right"></div>
        </div>
      </div>


      <div className="row1">
      <div className="text">
        <h1><span>0.3 </span>Deluxe Double <span>Room</span></h1>
      </div>
        <div className="carousel">
          <div className='space-left'></div>
          <div className="container-carousel">
            <h1>Room Description</h1>
            <p>This deluxe double room has a balcony with a wonderful mountain view. The bed is comfy based on our clients review and the size of the room is 27 square meters. The room also provides air conditioning and flat-screen TV.</p>
            
            <h1>Room Amenites</h1>
            <div className="row11">
              <IoBed style={{marginTop: 3, marginRight: 5, color: "rgb(125, 83, 0)"}}/>
              <p>1 Bed</p>
              <MdBalcony style={{marginTop: 3, marginRight: 5, color: "rgb(125, 83, 0)"}} />
              <p>Balcony</p>
              <FaParking style={{marginTop: 3, marginRight: 5, color: "rgb(125, 83, 0)"}} />
              <p>Parking</p>
            </div>
            <div className="row2">
              <MdRoomService style={{marginTop: 3, marginRight: 5, color: "rgb(125, 83, 0)"}} />
              <p>Room Service</p>
              <MdOutlineBreakfastDining style={{marginTop: 3, marginRight: 5, color: "rgb(125, 83, 0)"}} />
              <p>Breakfast</p>
              <CgScreen style={{marginTop: 3, marginRight: 5, color: "rgb(125, 83, 0)"}} />
              <p>Flat Screen TV</p>
            </div>
            <div className="row3">
              <IoLogoNoSmoking style={{marginTop: 3, marginRight: 5, color: "rgb(125, 83, 0)"}} />
              <p>No Smoking</p>
              <IoWifi style={{marginTop: 3, marginRight: 5, color: "rgb(125, 83, 0)"}} />
              <p>Free Wi-fi</p>
              <BsSnow style={{marginTop: 3, marginRight: 5, color: "rgb(125, 83, 0)"}} />
              <p>Air Conditioning</p>
            </div>
            <div className="bookingbutton">
            <a target={'_blank'} href="https://www.booking.com/hotel/al/paradise-point.html?aid=304142&label=gen173rf-1FCAEoggI46AdIM1gDaAaIAQGYATG4ARjIAQzYAQHoAQH4AQKIAgGiAg5sb2NhbGhvc3Q6MzAwMKgCBLgClZ6XmgbAAgHSAiQ5ZmZhNWVjNi1jMDhjLTQxMTItOWE3Ni05YmQ3NTRhNjFkZTXYAgXgAgE&sid=d1164f564a38bfe6db3a85fed331963f&dest_id=-105824;dest_type=city;dist=0;group_adults=3;group_children=0;hapos=1;hpos=1;no_rooms=1;req_adults=3;req_children=0;room1=A%2CA%2CA;sb_price_type=total;sr_order=popularity;srepoch=1665519438;srpvid=d2d38ea6f27101f9;type=total;ucfs=1&#hotelTmpl" className="bookbutton2">Book Now</a>
            </div>
          </div>
          <div className="carouselInner" style={{backgroundImage: `url(${images3[currImg3].img})`}}>
            <div className="left" onClick={() =>  { currImg3 > 0 && setCurrImg3(currImg3 - 1)}}><AiOutlineArrowLeft style={{cursor: "pointer", fontSize: 30}}/></div>
            <div className="center"></div>
            <div className="right" onClick={() => { currImg3 < images3  .length - 1  && setCurrImg3(currImg3 + 1)}}><AiOutlineArrowRight style={{cursor: "pointer", fontSize: 30}}/></div>
          </div>
          <div className="space-right"></div>
        </div>
      </div>

      <div className="row1">
      <div className="text">
        <h1><span>0.4 </span>Double <span>Room</span></h1>
      </div>
        <div className="carousel">
          <div className='space-left'></div>
          <div className="carouselInner" style={{backgroundImage: `url(${images4[currImg4].img})`}}>
            <div className="left" onClick={() =>  { currImg4 > 0 && setCurrImg4(currImg4 - 1)}}><AiOutlineArrowLeft style={{cursor: "pointer", fontSize: 30}}/></div>
            <div className="center"></div>
            <div className="right" onClick={() => { currImg4 < images4.length - 1  && setCurrImg4(currImg4 + 1)}}><AiOutlineArrowRight style={{cursor: "pointer", fontSize: 30}}/></div>
          </div>
          
          <div className="container-carousel">
            <h1>Room Description</h1>
            <p>This double room has a balcony. The bed is comfy based on our clients review and the size of the room is 30 square meters. The room also provides air conditioning and flat-screen TV.</p>
            
            <h1>Room Amenites</h1>
            <div className="row11">
              <IoBed style={{marginTop: 3, marginRight: 5, color: "rgb(125, 83, 0)"}}/>
              <p>1 Bed</p>
              <MdBalcony style={{marginTop: 3, marginRight: 5, color: "rgb(125, 83, 0)"}} />
              <p>Balcony</p>
              <FaParking style={{marginTop: 3, marginRight: 5, color: "rgb(125, 83, 0)"}} />
              <p>Parking</p>
            </div>
            <div className="row2">
              <MdRoomService style={{marginTop: 3, marginRight: 5, color: "rgb(125, 83, 0)"}} />
              <p>Room Service</p>
              <MdOutlineBreakfastDining style={{marginTop: 3, marginRight: 5, color: "rgb(125, 83, 0)"}} />
              <p>Breakfast</p>
              <CgScreen style={{marginTop: 3, marginRight: 5, color: "rgb(125, 83, 0)"}} />
              <p>Flat Screen TV</p>
            </div>
            <div className="row3">
              <IoLogoNoSmoking style={{marginTop: 3, marginRight: 5, color: "rgb(125, 83, 0)"}} />
              <p>No Smoking</p>
              <IoWifi style={{marginTop: 3, marginRight: 5, color: "rgb(125, 83, 0)"}} />
              <p>Free Wi-fi</p>
              <BsSnow style={{marginTop: 3, marginRight: 5, color: "rgb(125, 83, 0)"}} />
              <p>Air Conditioning</p>
            </div>
            <div className="bookingbutton">
            <a target={'_blank'} href="https://www.booking.com/hotel/al/paradise-point.html?aid=304142&label=gen173rf-1FCAEoggI46AdIM1gDaAaIAQGYATG4ARjIAQzYAQHoAQH4AQKIAgGiAg5sb2NhbGhvc3Q6MzAwMKgCBLgClZ6XmgbAAgHSAiQ5ZmZhNWVjNi1jMDhjLTQxMTItOWE3Ni05YmQ3NTRhNjFkZTXYAgXgAgE&sid=d1164f564a38bfe6db3a85fed331963f&dest_id=-105824;dest_type=city;dist=0;group_adults=3;group_children=0;hapos=1;hpos=1;no_rooms=1;req_adults=3;req_children=0;room1=A%2CA%2CA;sb_price_type=total;sr_order=popularity;srepoch=1665519438;srpvid=d2d38ea6f27101f9;type=total;ucfs=1&#hotelTmpl" className="bookbutton2">Book Now</a>
            </div>
          </div>
          <div className="space-right"></div>
        </div>
      </div>

      

      
    </div>
  )
}

export default RoomsBackground