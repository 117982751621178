import React from 'react'
import './style.css'
import {FaFacebookSquare, FaInstagramSquare, FaMarkdown, FaTwitterSquare} from 'react-icons/fa'
import Logo from '../../images/logo.png'
import {FaMapMarkerAlt, FaPhone, FaEnvelope} from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { AiFillGoogleSquare } from 'react-icons/ai'
const Footer = () => {
  return (
    <div className="footer-distributed">

			<div className="footer-left">

				<a><img src={Logo} alt="ParadisePoint" /></a>

				<p className="footer-links">
					<Link to="/" className="link-1">Home</Link>
				
					<Link to="/about">About Us</Link>
					
					<Link to="/rooms">Rooms</Link>
					
					<Link to="/contact" >Contact Us</Link>
				</p>

				<p className="footer-company-name">developed by <a href="https://www.instagram.com/donaldothedev/">© donaldothedev</a></p>
			</div>

			<div className="footer-center">

				<div className='location'>
					 <div className='svg-icons'><FaMapMarkerAlt/> </div>
					<a target={"_blank"} className='mail' href="https://www.google.com/maps/place/Paradise+Point/@40.6141271,20.7760833,17.26z/data=!4m8!3m7!1s0x135a0a43f7e2559b:0x8b0463c156ad390c!5m2!4m1!1i2!8m2!3d40.6142807!4d20.7769062"><p><span>7 Street 6 Deshmoret 54</span>  Korce, Albania</p></a>
				</div>

				<div className='location'>
					<div className="svg-icons"><FaPhone /></div>
					<p><a className="mail" href="tel:068-4499-050">+355 68 4499 050</a></p>
				</div>

				<div className='location'>
				    <div className="svg-icons"><FaEnvelope /></div>
					<p><a className="mail" href="mailto:boutiqueparadisepoint@gmail.com">boutiqueparadisepoint@gmail.com</a></p>
				</div>

			</div>

			<div className="footer-right">

				<p className="footer-company-about">
					<span>Follow us in our social media !</span>
				</p>

				<div className="footer-icons">

					<a target={"_blank"} href="https://www.facebook.com/boutiqueparadisepoint"><FaFacebookSquare /></a>
					<a target={"_blank"} href="https://www.instagram.com/boutiqueparadisepoint/"><FaInstagramSquare /></a>
					<a target={"_blank"} href="https://www.google.com/travel/hotels/Kor%C3%A7%C3%AB/entity/CgsIjPK0tZX4mIKLARAB?q=boutique%20paradise%20point%20korce&g2lb=2502548%2C2503771%2C2503781%2C2504032%2C4258168%2C4270442%2C4284970%2C4291517%2C4306835%2C4518326%2C4597339%2C4718358%2C4723331%2C4731329%2C4757164%2C4814050%2C4816977%2C4826689%2C4849797%2C4852066%2C4861688%2C4865303%2C4871745%2C4878223%2C4878224%2C26483161&hl=en-AL&gl=al&cs=1&ssta=1&ts=CAESABogCgIaABIaEhQKBwjmDxAKGBESBwjmDxAKGBIYATICEAAqCQoFOgNBTEwaAA&rp=EIzytLWV-JiCiwEQzqD6mbHMmsM1EJyk0P-lscbVRRDHg8vgjfHW6Ak4AkAASAKiAQdLb3LDp8OrwAEDmgICCAA&ap=aAE&ictx=1&ved=0CAAQ5JsGahcKEwi4m_nWod76AhUAAAAAHQAAAAAQBA&utm_campaign=sharing&utm_medium=link&utm_source=htls"><AiFillGoogleSquare /></a>

				</div>

			</div>

		</div>
  )
}

export default Footer